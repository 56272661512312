<template>
    <v-menu offset-y left>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
                <country-flag :country="selectedLang" />
            </v-btn>
        </template>

        <v-list class="p-0">
            <v-list-item-group v-model="selectedLang">
                <v-list-item
                    class="p-0"
                    dense
                    v-for="(lang, index) in langList"
                    :value="lang"
                    :key="index"
                    :disabled="selectedLang === lang"
                >
                    <v-list-item-title class="px-0 py-0"
                        ><country-flag :country="lang"
                    /></v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-menu>
</template>

<script>
import CountryFlag from "vue-country-flag";
import { mapActions } from "vuex";
export default {
    name: "LanguageSelect",
    components: { "country-flag": CountryFlag },
    data() {
        return {
            selectedLang: "",
            langList: Object.keys(this.$i18n.messages),
        };
    },
    methods: {
        ...mapActions({
            changeLocale: "locale/changeLocale",
        }),
    },
    watch: {
        selectedLang(lang) {
            this.changeLocale(lang);
        },
    },
    created() {
        this.selectedLang = this.$i18n.locale;
    },
};
</script>